import React, { Fragment } from 'react'
import useMedia from 'use-media'
import Layout from '../layout'
import { SEO, Overview, More, Action, Feature, Benefits } from '../components'

import SDKsIcon from '../assets/icons/sdks.svg'
import CloudIcon from '../assets/icons/cloud.svg'
import TechSupportIcon from '../assets/icons/tech-support.svg'

import ProgrammableChatOgImage from '../assets/images/programmable-chat-seo-image.png'

const ProgrammableChat = () => {
  const isWide = useMedia({ minWidth: 768 })
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Programmable Chat"
        description="A fully extendable chat service for live and async messaging"
        image={ProgrammableChatOgImage}
      />
      <div className="u-bg-black-3">
        <Overview
          title="Programmable Chat"
          subtitle={
            <Fragment>
              A fully extendable chat service
              <br />
              for live and async messaging
            </Fragment>
          }
          image={{
            name: 'programmableChatOverview',
            maxHeight: 500,
          }}
          features={[
            {
              title: 'Open Protocol',
              description:
                'A fully documented, open, flexible, and event-oriented messaging protocol with built-in support for rich content.',
              externalLink: `${process.env.GATSBY_DOCS_URL}messaging/`,
            },
            {
              title: 'Headless Live Chat',
              description:
                'Swap the native Chat Widget or Agent App with custom solutions. Make use of the entire system or just parts of it.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }messaging#agent-chat-api`,
            },
            {
              title: 'Chatbots API',
              description:
                'Build conversational interfaces with chatbots as first-class citizens. Create complex automations with webhooks.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }messaging/#bot-agents`,
            },
          ]}
          button={{
            label: 'See Messaging Docs',
            link: `${process.env.GATSBY_DOCS_URL}messaging/`,
          }}
        />
      </div>

      <div className="u-bg-black-3 u-Pt-xl u-Pb-sm" id="sample-chatbot">
        <section className="o-container u-flex u-flex-col-rev md:u-flex-row u-items-center md:u-justify-center">
          <iframe
            title="ChatBot"
            src={process.env.GATSBY_CHATBOT_FOR_DEVELOPERS_URL}
            style={{
              border: 'none',
              width: '320px',
              height: '550px',
              display: 'block',
              borderRadius: '8px',
            }}
          />
          <div
            className="u-Mb-md md:u-Ml-md"
            style={{ width: isWide ? '50%' : '100%' }}
          >
            <h3 className="u-text-p10">Live ChatBot Sample</h3>
            <h2 className="u-text-p3">
              Fully programmable
              <br />
              rich content messaging
            </h2>
            <h4 className="u-text-p6">
              Build engaging conversations with native support for rich
              messages. Bring snappy interactions to chats and set up automated
              responses. Play with the example to give it a try!
            </h4>
          </div>
        </section>
      </div>

      <div className="u-bg-black-3 u-Pt-2xl u-Pb-xs">
        <Feature
          inverse
          title={
            <Fragment>
              RTM &amp; Web transports <br />
              for unlimited flexibility
            </Fragment>
          }
          description="Use Programmable Chat in all kinds of environments. Leverage the real-time socket connection with push messages or use the standard web communication and webhooks."
          image="designSystem"
        />
      </div>
      <div className="u-bg-black2 u-Pt-xl u-Pb-xs">
        <Benefits
          content={[
            {
              icon: CloudIcon,
              label: 'Webhooks & pushes',
              description:
                'Use web & RTM transports with the full support for webhooks and pushes.',
            },
            {
              icon: SDKsIcon,
              label: 'Shipped with SDKs',
              description:
                'Get the same Go and JavaScript SDKs we use to build our integrations.',
            },
            {
              icon: TechSupportIcon,
              label: 'Technical support',
              description:
                'Our highly skilled support teams are ready to get you up and running.',
            },
          ]}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-3xl u-Pb-xs">
        <Feature
          title={
            <>
              Plays well with
              <br /> all environments
            </>
          }
          description="We provide SDKs and boilerplates for JavaScript and Go, but our APIs may be used in any environment of choice. Get up and running fast with sample apps and thorough documentation."
          button={{
            link: `${
              process.env.GATSBY_DOCS_URL
            }messaging/chat-sdk#simple-agent`,
            label: 'See Messaging APIs docs',
          }}
          image="customerSdk"
        />
      </div>
      <div className="u-bg-black-3 u-Pt-sm u-Pb-2xl">
        <More
          label={
            <Fragment>
              See other
              <span className="u-text-mark u-border-red"> Platform </span>
              products
            </Fragment>
          }
          links={[
            {
              title: 'Programmable Chat',
              description:
                'Open messaging protocol for all types of communication.',
              link: '/programmable-chat/',
            },
            {
              title: 'Reporting APIs',
              description:
                'Build data-driven dashboards and advanced reporting tools.',
              link: '/data-and-reporting/',
            },
            {
              title: 'Open Chat Widget',
              description:
                'Beautiful widget for demanding developers and designers.',
              link: '/omnichannel/',
            },
          ]}
        />
      </div>
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="buildEngagingChat"
          title={
            <>
              Create dynamic <br />
              messaging experiences
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: 'Open Developer Console',
          }}
          link={{
            link: `${process.env.GATSBY_DOCS_URL}messaging/`,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default ProgrammableChat
